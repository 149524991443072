<template>
    <div>
        <h2 class="mt-12" v-if="previewMediaItemDataSrc">{{text.preview}}</h2>

        <div class="columns" v-if="previewMediaItemDataSrc">
            <div class="content">
                <div class="panel panel-body">
                    <span class="image-border"><img :src="previewMediaItemDataSrc" :alt="text.preview_image" /></span>
                </div>
            </div>
            <div class="sidebar-right sidebar-right-full">
                <Form v-model="mediaGroupSelectData" ref="form"/>

                <a href="#" :title="text.save_as_new" class="btn" @click="saveChanges($event)" @keyup.enter="saveChanges($event)">{{text.save_as_new}}</a>
                <router-link :to="{ name: 'AdminMediaGroupMediaView' }" :title="text.cancel" class="btn btn-tertiary btn-smyspace" target="_blank">{{text.cancel}}</router-link>
            </div>
        </div>

        <div class="columns" v-if="mediaItemData.name">
            <div class="sidebar-left sidebar-left-full">
                <div class="panel panel-primary panel-no-shadow">
                    <div class="panel-heading">
                        <div class="panel-heading-text">Crop</div>
                    </div>
                    <div class="panel-content">
                        <div class="button-group clearfix mb-8" v-if="imageCreationOptions">
                            <div class="button-group-left button-group-combine">
                                <a href="#" class="btn btn-option btn-small btn-smxspace btn-smyspace" v-for="crop in sortedImageCropOptions" :key="crop.alias" :class="[{'btn-active' : (imageSelectedCrop === crop.alias)}]" @click="setImageCrop($event, crop.alias)" @keyup.enter="setImageCrop($event, crop.alias)">{{crop.label}}</a>
                            </div>
                            <div class="button-group-right" v-if="imageCreationOptions.enableHorizontalFlip">
                                <a href="#" class="btn btn-small btn-noxspace btn-noyspace btn-tertiary" @click="setImageFlipX($event)" @keyup.enter="setImageFlipX($event)">{{text.flip_x}}</a>
                            </div>
                        </div>
                        <div class="image-cropper-wrapper" v-if="imageCreationOptions && mediaItemData.name">
                            <img id="cropper-me" v-auth-src="mediaBasePath + mediaItemData.previewFilePath" />
                        </div>
                        <p v-else>Loading options</p>
                    </div>
                </div>
            </div>
            <div class="sidebar-right sidebar-right-full">
                <div class="panel panel-primary panel-no-shadow">
                    <div class="panel-heading">
                        <div class="panel-heading-text">Text</div>
                    </div>
                    <div class="panel-content">
                        <Form v-model="imageTextFormInputs" ref="imageTextForm" v-if="imageTextFormInputs" />
                        <p v-else>{{ text.no_text_options }}</p>
                        <div class="button-group clearfix mb-8" v-if="showManualTextPlacement">
                            <h3>{{ text.enable_manual_text_position }}</h3>
                            <div class="button-group-left button-group-combine">
                                <a href="#" class="btn btn-option btn-small btn-noxspace btn-smyspace" :class="[{'btn-active' : enableManualTextPlacement}]" @click="changeManualTextPlacement($event, true)" @keyup.enter="changeManualTextPlacement($event, true)">{{text.yes}}</a>
                                <a href="#" class="btn btn-option btn-small btn-noxspace btn-smyspace" :class="[{'btn-active' : !enableManualTextPlacement}]" @click="changeManualTextPlacement($event, false)" @keyup.enter="changeManualTextPlacement($event, false)">{{text.no}}</a>
                            </div>
                            <div class="button-group-right" v-if="previewMediaItemDataSrc && showManualTextPlacement && enableManualTextPlacement">
                                <div class="form-input form-input-inline">
                                    <label for="xPos">{{ text.horizontal }}</label>
                                    <div class="form-input">
                                        <input id="xPos" type="number" min="0" max="100" :step="stepChange" v-model="manualTextXpos" />
                                    </div>
                                </div>
                                <div class="form-input form-input-inline">
                                    <label for="yPos">{{ text.vertical }}</label>
                                    <div class="form-input">
                                        <input id="yPos" type="number" min="0" max="100" :step="stepChange" v-model="manualTextYpos" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="text-placement" v-if="previewMediaItemDataSrc && showManualTextPlacement && enableManualTextPlacement">
                            <div class="text-placement-grid">
                                <div class="text-placement-grid-item">
                                    <div class="form-range form-range-vertical">
                                        <div class="range-button-wrapper">
                                            <button @click="changeManualTextPosition($event, 'y', stepChange * -1)" class="btn btn-secondary range-control minus"><span class="icon"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path d="M15.16,7.16H.78A.82.82,0,0,0,0,8v.05a.82.82,0,0,0,.84.79H15.22A.82.82,0,0,0,16,8V8A.82.82,0,0,0,15.16,7.16Z"/></svg></span></button>
                                        </div>
                                        <div class="range-wrapper">
                                            <input class="text-placement-range text-placement-range-y" type="range" min="0" max="100" :step="stepChange" v-model="manualTextYpos" orient="vertical" />
                                        </div>
                                        <div class="range-button-wrapper">
                                            <button @click="changeManualTextPosition($event, 'y', stepChange)" class="btn btn-secondary range-control plus"><span class="icon"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path d="M7.4,15.76a.83.83,0,0,1-.24-.6V8.85H.84A.82.82,0,0,1,0,8.05V8a.82.82,0,0,1,.78-.84H7.15V.84A.82.82,0,0,1,8,0H8a.82.82,0,0,1,.84.78V7.15h6.31A.82.82,0,0,1,16,8V8a.82.82,0,0,1-.78.84H8.85v6.31a.82.82,0,0,1-.79.84H8A.81.81,0,0,1,7.4,15.76Z"/></svg></span></button>
                                        </div>
                                    </div>
                                </div>
                                <div class="text-placement-grid-item"><span class="image-border text-placement-wrapper"><img :src="previewMediaItemDataSrc" :alt="text.preview_image" /><span class="text-placement-wrapper-icon" :style="manualTextPosStyle"><span class="text-placement-wrapper-icon-inner"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path d="M7.4,15.76a.83.83,0,0,1-.24-.6V8.85H.84A.82.82,0,0,1,0,8.05V8a.82.82,0,0,1,.78-.84H7.15V.84A.82.82,0,0,1,8,0H8a.82.82,0,0,1,.84.78V7.15h6.31A.82.82,0,0,1,16,8V8a.82.82,0,0,1-.78.84H8.85v6.31a.82.82,0,0,1-.79.84H8A.81.81,0,0,1,7.4,15.76Z"/></svg><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path d="M7.4,15.76a.83.83,0,0,1-.24-.6V8.85H.84A.82.82,0,0,1,0,8.05V8a.82.82,0,0,1,.78-.84H7.15V.84A.82.82,0,0,1,8,0H8a.82.82,0,0,1,.84.78V7.15h6.31A.82.82,0,0,1,16,8V8a.82.82,0,0,1-.78.84H8.85v6.31a.82.82,0,0,1-.79.84H8A.81.81,0,0,1,7.4,15.76Z"/></svg></span></span></span></div>
                                <div class="text-placement-grid-item"></div>
                                <div class="text-placement-grid-item">
                                    <div class="form-range">
                                        <div class="range-button-wrapper">
                                            <button @click="changeManualTextPosition($event, 'x', stepChange * -1)" class="btn btn-secondary range-control minus"><span class="icon"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path d="M15.16,7.16H.78A.82.82,0,0,0,0,8v.05a.82.82,0,0,0,.84.79H15.22A.82.82,0,0,0,16,8V8A.82.82,0,0,0,15.16,7.16Z"/></svg></span></button>
                                        </div>
                                        <div class="range-wrapper">
                                            <input class="text-placement-range text-placement-range-x" type="range" min="0" max="100" :step="stepChange" v-model="manualTextXpos" />
                                        </div>
                                        <div class="range-button-wrapper">
                                            <button @click="changeManualTextPosition($event, 'x', stepChange)" class="btn btn-secondary range-control plus"><span class="icon"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path d="M7.4,15.76a.83.83,0,0,1-.24-.6V8.85H.84A.82.82,0,0,1,0,8.05V8a.82.82,0,0,1,.78-.84H7.15V.84A.82.82,0,0,1,8,0H8a.82.82,0,0,1,.84.78V7.15h6.31A.82.82,0,0,1,16,8V8a.82.82,0,0,1-.78.84H8.85v6.31a.82.82,0,0,1-.79.84H8A.81.81,0,0,1,7.4,15.76Z"/></svg></span></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import lang from "@/shared/lang";
    import {log, debounce, showDefaultServerErrorMessage} from "@/shared/utils";
    import {getMediaGroupList, getImageEditPreview} from "@/shared/dataservice";
    import {MEDIA_ROOT} from "@/shared/consts";
    import {mapActions, mapState} from "vuex";

    import Form from "@/components/form/form";
    import {formWrapperMixin} from "@/views/mixins/formwrapper";

    import {formatContentMixin} from "@/views/mixins/formatContent";
    import {mediaGroupActionsMixin} from "@/views/mixins/mediaGroupActions";

    import 'cropperjs/dist/cropper.css';
    import Cropper from 'cropperjs';

    export default {
        name: 'MediaGroupMediaEdit',
        components: {Form},
        mixins: [formatContentMixin, mediaGroupActionsMixin, formWrapperMixin],
        data() {
            return {
                mediaGroupData: [],
                mediaItemData: [],
                cropImageElement: '',
                cropper: null,
                imageCreationOptions: null,
                sortedImageCropOptions: null,
                imageSelectedCrop: null,
                imageFlippedX: false,
                isLoadingPreview: false,
                previewMediaItemDataSrc: null,
                imageTextFormInputs: null,
                currentCropTextOptions: null,
                currentTextPosition:null,
                currentTextStyle:null,
                currentTextStyleVariation:null,
                currentText:null,
                imageTextByPosition: null,
                imageTextPositionOptions: null,
                imageTextStyleOptions: null,
                imageTextStyleVariationOptions: null,
                lastPreviewImageData: null,
                enableManualTextPlacement: false,
                showManualTextPlacement: false,
                manualTextXpos: null,
                manualTextYpos: null,
                stepChange: 0.1,
                debouncedRefreshPreviewImage: null,
                mediaGroupSelectData: [
                    {
                        id: 1,
                        type: 'select',
                        name: 'mediaGroup',
                        label: 'Select folder',
                        placeholder: "",
                        tooltip: "",
                        value: "",
                        options: []
                    },
                ]
            }
        },
        computed: {
            ...mapState(['pageTitle']), // imports from store
            mediaGroupID() {
                return this.$route.params.mediaGroupID;
            },
            mediaItemID() {
                return this.$route.params.mediaItemID;
            },
            text() {
                return lang;
            },
            pageTitlePrefix() {
                return this.mediaItemData.archived ? " (" + this.text.archived + ") " : "";
            },
            mediaBasePath() {
                return MEDIA_ROOT;
            },
            manualTextPosStyle() {
                return this.manualTextXpos === null || this.manualTextXpos === null ? 'display:none;' : 'top:' + this.manualTextYpos + "%; left:" + this.manualTextXpos + "%;"
            }
        },
        methods: {
            ...mapActions(["setPageTitleAction", "setBreadCrumbsAction"]),
            async init() {
                // load existing media item to be edited
                await this.refreshMediaItem(this.mediaItemID);
                this.updatePageTitle();

                //log(['Media item data loaded', this.mediaItemData]);

                // loading editting options
                await this.loadImageCreationOptions();

                log(['Image creation options loaded', this.imageCreationOptions]);

                // init image crop
                this.cropImageElement = document.getElementById('cropper-me');
                this.cropImageElement.onload = () => {
                    //log(['image loaded']);
                    this.initImageCrop();
                };
            },
            async refreshMediaItem() {
                await this.loadMediaGroupMediaItemByID(this.mediaItemID);
            },
            async getMediaGroupData() {
                this.mediaGroupData = await this.loadMediaGroupByID(this.mediaGroupID);

                // update breadcrumbs
                this.setBreadCrumbsAction({
                    route : this.$route.matched,
                    replace : [{
                        search : "%mediaGroupName%",
                        replace : this.mediaGroupData.name
                    }]
                });
            },
            changeManualTextPlacement(e, value) {
                if(e){
                    e.preventDefault();
                }
                this.enableManualTextPlacement = value;
                this.checkManualTextPlacement();
            },
            changeShowManualTextPlacement() {
                this.showManualTextPlacement = this.imageCreationOptions
                                                    && Object.prototype.hasOwnProperty.call(this.imageCreationOptions, 'crops')
                                                    && Object.prototype.hasOwnProperty.call(this.imageCreationOptions.imageSchemas, this.imageSelectedCrop)
                                                    && Object.prototype.hasOwnProperty.call(this.imageCreationOptions.imageSchemas[this.imageSelectedCrop], 'allowManualTextPlacement')
                                                        ? this.imageCreationOptions.imageSchemas[this.imageSelectedCrop].allowManualTextPlacement
                                                        : false;
                this.checkManualTextPlacement();
            },
            checkManualTextPlacement() {
                if(this.showManualTextPlacement) {
                    this.initManualTextPlacement();
                } else {
                    this.resetManualTextPlacement();
                }
            },
            initManualTextPlacement() {
                this.manualTextXpos = this.imageCreationOptions.imageSchemas[this.imageSelectedCrop].textStyles[this.getFormDataValueByName(this.imageTextFormInputs, "textStyle")].textOriginX;
                this.manualTextYpos = this.imageCreationOptions.imageSchemas[this.imageSelectedCrop].textStyles[this.getFormDataValueByName(this.imageTextFormInputs, "textStyle")].textOriginY;
                /*log(['InitManualTextPlacement',
                    this.manualTextXpos,
                    this.manualTextYpos,
                    this.imageCreationOptions.imageSchemas[this.imageSelectedCrop].textStyles[this.getFormDataValueByName(this.imageTextFormInputs, "textStyle")].textOriginX,
                    this.getCroppedImageWidth(),
                    this.imageCreationOptions.imageSchemas[this.imageSelectedCrop].textStyles[this.getFormDataValueByName(this.imageTextFormInputs, "textStyle")].textOriginY,
                    this.getCroppedImageHeight()]);*/
            },
            resetManualTextPlacement() {
                this.manualTextXpos = null;
                this.manualTextYpos = null;
            },
            getCroppedImageWidth() {
                if (this.imageCreationOptions.imageSchemas[this.imageSelectedCrop].width) {
                    return this.imageCreationOptions.imageSchemas[this.imageSelectedCrop].width;
                }
                else {
                    return this.cropper.getImageData().naturalWidth;
                }
            },
            getCroppedImageHeight() {
                if (this.imageCreationOptions.imageSchemas[this.imageSelectedCrop].height) {
                    return this.imageCreationOptions.imageSchemas[this.imageSelectedCrop].height;
                }
                else {
                    return this.cropper.getImageData().naturalHeight;
                }
            },
            changeManualTextPosition(e, axis, increment) {
                e.preventDefault();
                if (axis === 'x') {
                    this.manualTextXpos = parseFloat(parseFloat(this.manualTextXpos) + parseFloat(increment)).toFixed(1);
                }
                else {
                    this.manualTextYpos = parseFloat(parseFloat(this.manualTextYpos) + parseFloat(increment)).toFixed(1);
                }
            },
            updatePageTitle() {
                //log(["What info do I have here?", this.$route]);

                // update page title
                this.setPageTitleAction({
                    title : this.$route.meta.pageTitle.replace("%s%", this.mediaItemData.name) + this.pageTitlePrefix,
                    isEditable: false,
                    editableTitle : this.mediaItemData.name,
                    changeSaved : false
                });
            },
            initTextEdit(textOptions) {
                this.currentCropTextOptions = textOptions;

                this.imageTextByPosition = [];

                this.imageTextPositionOptions = [];
                for (const [key, textStyle] of Object.entries(this.currentCropTextOptions)) {
                    this.imageTextPositionOptions.push({'value' : key, 'text' : textStyle.label});
                    this.imageTextByPosition[key] = {
                            styleValue: null,
                            variationValue: null,
                            text: ''
                        };
                }

                this.updateTextOptions();
            },
            updateTextOptions() {
                log([
                    'updateTextOptions',
                    this.imageTextPositionOptions
                ]);

                let lastText = this.currentText;
                this.currentText = this.imageTextFormInputs
                                    && this.getFormDataValueByName(this.imageTextFormInputs, "text")
                                        ? this.getFormDataValueByName(this.imageTextFormInputs, "text")
                                        : '';

                if(this.currentTextPosition && (lastText !== this.currentText)) {
                    this.imageTextByPosition[this.currentTextPosition].text = this.currentText;
                }

                let lastCurrentTextPosition = this.currentTextPosition;
                this.currentTextPosition = this.imageTextFormInputs
                                                && this.getFormDataValueByName(this.imageTextFormInputs, "textPosition")
                                                && this.imageTextPositionOptions.map((element) => { return element.value }).includes(this.getFormDataValueByName(this.imageTextFormInputs, "textPosition"))
                                                    ? this.getFormDataValueByName(this.imageTextFormInputs, "textPosition")
                                                    : this.imageTextPositionOptions[0].value;

                let lastCurrentTextStyle = this.currentTextStyle;
                this.currentTextStyle = this.imageTextFormInputs
                                            && this.getFormDataValueByName(this.imageTextFormInputs, "textStyle")
                                                ? this.getFormDataValueByName(this.imageTextFormInputs, "textStyle")
                                                : '';

                this.currentTextStyleVariation = this.imageTextFormInputs
                                            && this.getFormDataValueByName(this.imageTextFormInputs, "textStyleVariation")
                                                ? this.getFormDataValueByName(this.imageTextFormInputs, "textStyleVariation")
                                                : '';

                const positionHasChanged = lastCurrentTextPosition !== this.currentTextPosition;

                log(['Current Text Position', this.currentTextPosition, positionHasChanged]);

                this.updateTextStyleOptions(this.currentTextPosition);

                if(positionHasChanged && this.imageTextByPosition[this.currentTextPosition].styleValue === null) {
                    this.imageTextByPosition[this.currentTextPosition].styleValue = this.imageTextStyleOptions[0].value;
                } else if (!positionHasChanged) {
                    this.imageTextByPosition[this.currentTextPosition].styleValue = this.imageTextFormInputs
                        && this.getFormDataValueByName(this.imageTextFormInputs, "textStyle")
                        && this.imageTextStyleOptions.map((element) => { return element.value }).includes(this.getFormDataValueByName(this.imageTextFormInputs, "textStyle"))
                            ? this.getFormDataValueByName(this.imageTextFormInputs, "textStyle")
                            : this.imageTextStyleOptions[0].value;
                }

                const styleHasChanged = lastCurrentTextStyle !== this.currentTextStyle || positionHasChanged;

                //log(['Current Text Style', this.imageTextByPosition[this.currentTextPosition].styleValue, styleHasChanged]);

                this.updateTextStyleVariationOptions(this.currentTextPosition, this.imageTextByPosition[this.currentTextPosition].styleValue);

                if(styleHasChanged && this.imageTextByPosition[this.currentTextPosition].variationValue === null) {
                    log('style changed and null');
                    this.imageTextByPosition[this.currentTextPosition].variationValue = this.imageTextStyleVariationOptions[0].value;
                } else if (!styleHasChanged) {
                    log('style NOT changed');
                    this.imageTextByPosition[this.currentTextPosition].variationValue = this.imageTextFormInputs
                        && this.getFormDataValueByName(this.imageTextFormInputs, "textStyleVariation")
                        && this.imageTextStyleVariationOptions.map((element) => { return element.value }).includes(this.getFormDataValueByName(this.imageTextFormInputs, "textStyleVariation"))
                            ? this.getFormDataValueByName(this.imageTextFormInputs, "textStyleVariation")
                            : this.imageTextStyleVariationOptions[0].value;
                }

                //log(['Current Text Variation', this.imageTextByPosition[this.currentTextPosition].variationValue]);

                log(['Current text state', this.imageTextByPosition]);

                this.imageTextFormInputs = [
                    {
                        id: 1,
                        type: 'select',
                        name: 'textPosition',
                        label: 'Text position',
                        placeholder: '',
                        tooltip: '',
                        value : this.currentTextPosition,
                        options : this.imageTextPositionOptions,
                    },
                    this.imageTextStyleOptions ? {
                        id: 2,
                        type: 'select',
                        name: 'textStyle',
                        label: 'Text style',
                        placeholder: '',
                        tooltip: '',
                        value : this.imageTextByPosition[this.currentTextPosition].styleValue,
                        options : this.imageTextStyleOptions,
                        disabled : this.imageTextStyleOptions[0].value === 'default' ? true : false,
                        hidden: this.imageTextStyleOptions[0].value === 'default' ? true : false
                    } : null,
                    this.imageTextStyleVariationOptions ? {
                        id: 3,
                        type: 'select',
                        name: 'textStyleVariation',
                        label: 'Text variant',
                        placeholder: '',
                        tooltip: '',
                        value : this.imageTextByPosition[this.currentTextPosition].variationValue,
                        options : this.imageTextStyleVariationOptions,
                        disabled : this.imageTextStyleVariationOptions[0].value === 'default' ? true : false,
                        hidden: this.imageTextStyleVariationOptions[0].value === 'default' ? true : false
                    } : null,
                    {
                        id: 4,
                        type: 'textarea',
                        name: 'text',
                        label: 'Text',
                        placeholder: this.text.enter_text,
                        tooltip: '',
                        value : this.imageTextByPosition[this.currentTextPosition].text,
                        rows : 4
                    }
                ].filter(Boolean);
            },
            updateTextStyleOptions(currentTextPositionValue) {
                log(['updateTextStyleOptions', currentTextPositionValue]);
                this.imageTextStyleOptions = [];

                if(
                    Object.prototype.hasOwnProperty.call(this.currentCropTextOptions, currentTextPositionValue)
                    && Object.prototype.hasOwnProperty.call(this.currentCropTextOptions[currentTextPositionValue], 'textStyles')
                    && Object.keys(this.currentCropTextOptions[currentTextPositionValue].textStyles).length > 0
                ) {
                    for (const [key, textStyle] of Object.entries(this.currentCropTextOptions[currentTextPositionValue].textStyles)) {
                        this.imageTextStyleOptions.push({'value' : key, 'text' : textStyle.label});
                    }
                } else {
                    this.imageTextStyleOptions.push({'value' : 'default', 'text' : this.text.default});
                }
            },
            updateTextStyleVariationOptions(currentTextPositionValue, currentStyleValue) {
                log(['updateTextStyleVariationOptions', currentTextPositionValue, currentStyleValue, this.currentCropTextOptions[currentTextPositionValue]]);
                this.imageTextStyleVariationOptions = [];

                if(
                    currentStyleValue !== 'default'
                    && Object.prototype.hasOwnProperty.call(this.currentCropTextOptions[currentTextPositionValue], 'textStyles')
                    && Object.prototype.hasOwnProperty.call(this.currentCropTextOptions[currentTextPositionValue]['textStyles'], currentStyleValue)
                    && Object.prototype.hasOwnProperty.call(this.currentCropTextOptions[currentTextPositionValue]['textStyles'][currentStyleValue], 'variants')
                    && Object.keys(this.currentCropTextOptions[currentTextPositionValue]['textStyles'][currentStyleValue].variants).length > 0
                ) {
                    for (const [key, textVariation] of Object.entries(this.currentCropTextOptions[currentTextPositionValue]['textStyles'][currentStyleValue].variants)) {
                        this.imageTextStyleVariationOptions.push({'value' : key, 'text' : textVariation.label});
                    }
                } else {
                    this.imageTextStyleVariationOptions.push({'value' : 'default', 'text' : this.text.default});
                }
            },
            initImageCrop() {
                this.cropper = new Cropper(this.cropImageElement, {
                    viewMode: 1,
                    autoCrop: false,
                    movable: false,
                    zoomable: false,
                    ready: (/*event*/) => {
                        //log(['Cropper ready', event]);
                        this.setImageCrop(null, this.sortedImageCropOptions[0].alias);
                    },
                    cropend: (/*event*/) => {
                        //log(['cropper cropend', event]);
                        this.refreshPreviewImage();
                    }
                });
                log(['Cropper initialised', this.cropper]);
            },
            setImageCrop(e, alias) {
                //log(['set image crop', e, alias]);
                if (e) {
                    e.preventDefault();
                }
                this.imageSelectedCrop = alias;

                if (Object.prototype.hasOwnProperty.call(this.imageCreationOptions.imageSchemas, this.imageSelectedCrop)){
                    //log(['valid image crop', alias, this.imageCreationOptions.imageSchemas[this.imageSelectedCrop]]);
                    if (this.currentCropIsNoCrop()) {
                        this.cropper.setAspectRatio(NaN);
                        this.cropper.clear();
                        this.cropper.disable();
                    }
                    else if (this.currentCropIsFreeCrop()) {
                        //log(['Is free crop']);
                        this.cropper.enable();
                        this.cropper.setAspectRatio(NaN);
                        this.cropper.crop();
                    }
                    else {
                        //log(['cropping to', this.imageCreationOptions.imageSchemas[this.imageSelectedCrop].width, this.imageCreationOptions.imageSchemas[this.imageSelectedCrop].height, (this.imageCreationOptions.imageSchemas[this.imageSelectedCrop].width / this.imageCreationOptions.imageSchemas[this.imageSelectedCrop].height)]);
                        this.cropper.enable();
                        this.cropper.setAspectRatio(this.imageCreationOptions.imageSchemas[this.imageSelectedCrop].width / this.imageCreationOptions.imageSchemas[this.imageSelectedCrop].height);
                        this.cropper.crop();
                    }

                    // update text styles
                    if (Object.prototype.hasOwnProperty.call(this.imageCreationOptions.imageSchemas[this.imageSelectedCrop], 'textPositions')) {
                        //log(['TextStyles', this.imageCreationOptions.imageSchemas[this.imageSelectedCrop].textPositions]);
                        if (this.imageCreationOptions.imageSchemas[this.imageSelectedCrop].textPositions) {
                            this.initTextEdit(this.imageCreationOptions.imageSchemas[this.imageSelectedCrop].textPositions);
                        }
                        else {
                            this.initTextEdit(null);
                        }
                    }

                    this.changeShowManualTextPlacement();
                    this.changeManualTextPlacement(null,false);

                    this.refreshPreviewImage();
                } else {
                    log(['Invalid crop alias']);
                }
            },
            setImageFlipX(e) {
                e.preventDefault();
                //log(['set image flip', !this.imageFlippedX]);

                this.imageFlippedX = !this.imageFlippedX;
                this.cropper.scaleX(this.imageFlippedX ? -1 : 1);

                this.refreshPreviewImage();
            },
            async saveChanges(e) {
                e.preventDefault();

                const imageData = this.getEditedImageData();

                log(['save changes', imageData]);

                const result = imageData
                    ? await this.createMediaItemFromCrop(imageData)
                    : null;

                if(result && result.status === 200){
                    await this.$router.push( { name: 'AdminMediaGroupMediaView', params: { 'mediaGroupID': imageData.mediaGroupId, 'mediaItemID' : result.data.data.id, message: 'added' } } );
                }
                else
                {
                    showDefaultServerErrorMessage("Save cropped media item");
                }
            },
            async loadPreviewImage(imageData) {
                this.isLoadingPreview = true;
                let result = await getImageEditPreview(imageData);
                this.previewMediaItemDataSrc = result.data;
                this.isLoadingPreview = false;
            },
            async refreshPreviewImage() {
                if (this.debouncedRefreshPreviewImage === null) {
                    this.debouncedRefreshPreviewImage = debounce(this.doRefreshPreviewImage);
                }

                this.debouncedRefreshPreviewImage();
            },
            async doRefreshPreviewImage() {
                const previewImageData = this.getEditedImageData();
                const previewImageDataIsDifferent = this.previewImageDataObjectsDifferent(previewImageData);
                if (!this.isLoadingPreview && previewImageData && previewImageDataIsDifferent) {
                    //log('Trigger refresh image');
                    await this.loadPreviewImage(previewImageData);
                    this.lastPreviewImageData = previewImageData;
                } else if (previewImageDataIsDifferent) {
                    //log('Trigger retry');
                    // delay call until last call completes
                    setTimeout(function () {
                        //log('Retrying');
                        this.refreshPreviewImage();
                    }.bind(this), 500);
                } else {
                    log('preview image objects the same... not requesting again');
                }
            },
            getEditedImageData() {
                if (!this.cropper) {
                    return false;
                }

                const canvasData = this.cropper.getCanvasData();
                const cropBoxData = this.cropper.getCropBoxData();

                // log(['Current cropper data', canvasData, cropBoxData]);

                return {
                    'mediaGroupId': this.getFormDataValueByName(this.mediaGroupSelectData, "mediaGroup"),
                    'existingMediaId': this.mediaItemID,
                    'imageSchemaAlias': this.imageSelectedCrop,
                    'leftPercent': this.currentCropIsNoCrop() ? null : this.convertCanvasPixelToPercent(cropBoxData.left, canvasData.width),
                    "topPercent": this.currentCropIsNoCrop() ? null : this.convertCanvasPixelToPercent(cropBoxData.top, canvasData.height),
                    'widthPercent': this.currentCropIsNoCrop() ? null : this.convertCanvasPixelToPercent(cropBoxData.width, canvasData.width),
                    'heightPercent': this.currentCropIsNoCrop() ? null : this.convertCanvasPixelToPercent(cropBoxData.height, canvasData.height),
                    'horizontalFlip': this.imageFlippedX,
                    'textBriefs': this.getCurrentTextBriefs()
                    /*
                    'textStyleAlias': this.getCurrentTextStyle(),
                    'text': this.getFormDataValueByName(this.imageTextFormInputs, "textStyle")
                        ? this.getFormDataValueByName(this.imageTextFormInputs, "text")
                        : null
                    ,
                    'textLeftPercent': this.showManualTextPlacement && this.enableManualTextPlacement
                        ? parseFloat(this.manualTextXpos)
                        : null,
                    'textTopPercent': this.showManualTextPlacement && this.enableManualTextPlacement
                        ? parseFloat(this.manualTextYpos)
                        : null
                        */
                };
            },
            getCurrentTextBriefs() {
                let textBriefs = [];

                Object.entries(this.imageTextByPosition).forEach((textByPosition) => {
                    const [key, data] = textByPosition;
                    if(data.styleValue !== null && data.variationValue !== null) {
                        textBriefs.push({
                            textPosition: key,
                            textStyleAlias: this.getCurrentTextStyleAlias(data),
                            text: data.text
                        });
                    }
                })

                return textBriefs;
            },
            getCurrentTextStyleAlias(data) {
                let aliasComponents = [];

                if(data.styleValue !== 'default') {
                    aliasComponents.push(data.styleValue);

                    if(data.variationValue !== 'default') {
                        aliasComponents.push(data.variationValue);
                    }
                }

                return aliasComponents.join('.');
            },
            currentCropIsNoCrop() {
                return Object.prototype.hasOwnProperty.call(this.imageCreationOptions.imageSchemas[this.imageSelectedCrop], 'isNoCrop') && this.imageCreationOptions.imageSchemas[this.imageSelectedCrop].isNoCrop === true;
            },
            currentCropIsFreeCrop() {
                return Object.prototype.hasOwnProperty.call(this.imageCreationOptions.imageSchemas[this.imageSelectedCrop], 'isFreeCrop') && this.imageCreationOptions.imageSchemas[this.imageSelectedCrop].isFreeCrop === true;
            },
            previewImageDataObjectsDifferent(current) {
                log(['Comparing preview image data', current, (JSON.stringify(current) !== JSON.stringify(this.lastPreviewImageData)), JSON.stringify(current), JSON.stringify(this.lastPreviewImageData)]);
                return JSON.stringify(current) !== JSON.stringify(this.lastPreviewImageData);
            },
            convertCanvasPixelToPercent(pixels, totalPixels) {
                return (pixels / totalPixels) * 100;
            },
            async loadMediaGroups() {
                let result = await getMediaGroupList();

                log(["Media Groups Result", result, this.mediaGroupSelectData]);

                if (result.status === 200) {
                    let newOptions = [];
                    result.data.data.forEach(mediaGroup => {
                      newOptions.push({value: mediaGroup.id, text: mediaGroup.name});
                    });

                    this.mediaGroupSelectData.find(row => row.name === 'mediaGroup').options = newOptions;
                    this.mediaGroupSelectData.find(row => row.name === 'mediaGroup').value = this.mediaGroupID;
                } else {
                    showDefaultServerErrorMessage("Load Media Group");
                }
            },
        },
        async mounted() {
            await this.getMediaGroupData();
            await this.loadMediaGroups();
            this.init();
        },
        watch: {
            'pageTitle.changeSaved' (){
                //log(["SAVED Watched page title change", this.pageTitle.editableTitle, this.mediaItemData.name, this.pageTitle.changeSaved]);

                if(this.pageTitle.changeSaved && (this.pageTitle.editableTitle !== this.mediaItemData.name)){
                    //log(["Title change has been saved and is different from original"]);
                    this.saveNewMediaItemName();
                }
            },
            imageTextFormInputs: {
                // This will let Vue know to look inside the array
                deep: true,

                // We have to move our method to a handler field
                handler() {
                    log(['Form data watch triggered!']);

                    if(
                        (this.currentTextPosition !== this.getFormDataValueByName(this.imageTextFormInputs, "textPosition"))
                        || (this.currentTextStyle !== this.getFormDataValueByName(this.imageTextFormInputs, "textStyle"))
                        || (this.currentTextStyleVariation !== this.getFormDataValueByName(this.imageTextFormInputs, "textStyleVariation"))
                        || (this.currentText !== this.getFormDataValueByName(this.imageTextFormInputs, "text"))) {
                        log(['trigger updateTextOptions']);
                        this.updateTextOptions();
                    }

                    this.refreshPreviewImage();
                }
            },
            imageCreationOptions: {
                deep: true,

                handler() {
                    //log(['Image Creation Options Changed', this.imageCreationOptions]);
                    this.sortedImageCropOptions = [];
                    if (Object.prototype.hasOwnProperty.call(this.imageCreationOptions, 'imageSchemas')) {
                        this.sortedImageCropOptions = Object.values(this.imageCreationOptions.imageSchemas).sort((a, b) => a.order - b.order);
                    }
                }
            },
            manualTextXpos: {
                handler() {
                    if (this.manualTextXpos !== null) {
                        if (this.manualTextXpos > 100) {
                           this.manualTextXpos = 100;
                        }
                        else if (this.manualTextXpos < 0) {
                            this.manualTextXpos = 0;
                        }
                    }

                    this.refreshPreviewImage();
                }
            },
            manualTextYpos: {
                handler() {
                    if (this.manualTextYpos !== null) {
                        if (this.manualTextYpos > 100) {
                            this.manualTextYpos = 100;
                        }
                        else if (this.manualTextYpos < 0) {
                            this.manualTextYpos = 0;
                        }
                    }

                    this.refreshPreviewImage();
                }
            }
        },
    }
</script>
